import _ from "lodash";
import { useState } from "react";
import ReactJson from "react-json-view";
import axios from "axios";

const defaults = {
  Start: "1956-01-01T00:00:00",
  End: "2021-08-25T00:00:00",
  fert_amount: "200",
  met_file: "AYR DPI RESEARCH STN.met",
  plant_stalks: "10",
  ratoon_stalks: "10",
  sowing_depth: "150",
  variety: "q117",
};

const baseUrl = "https://apsim.i-rat.net/api/";

const useData = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [elapsedTime, setElapsedTime] = useState();

  const fetch = async (endpoint, parameters) => {
    setIsLoading(true);
    const start = new Date().getTime();
    try {
      const { data } = await axios({
        method: "post",
        data: parameters,
        url: `${baseUrl}${endpoint}/`,
      });
      setData(data);
    } catch (e) {
      setData({
        error: "Request failed, check parameter values.",
        response: e,
      });
    }

    const end = new Date().getTime();
    setIsLoading(false);
    setElapsedTime(end - start);
  };
  return { isLoading, data, fetch, elapsedTime };
};

const generateUrl = (endpoint, parameters) => {
  let url = `${baseUrl}${endpoint}/`;
  if (!_.isEmpty(parameters)) {
    url += "?";
    url += _.join(
      _.map(_.toPairs(parameters), ([key, value]) => `${key}=${value}`),
      "&"
    );
  }
  return url;
};

function App() {
  const [values, setValues] = useState(defaults);
  const [endpoint, setEndpoint] = useState("single");
  const { isLoading, data, fetch, elapsedTime } = useData();
  const parameters = _.pick(
    values,
    _.reject(_.keys(defaults), (key) => _.isEqual(values[key], defaults[key]))
  );
  const url = generateUrl(endpoint, parameters);
  return (
    <div className="container">
      <div className="vh-100 d-flex flex-column">
        <div>
          <h1 className="text-center h2 my-2">APSIM serverless API demo</h1>
          <hr />
          <div className="row">
            {_.map(_.toPairs(values), ([key, value]) => (
              <div key={key} className="mb-3 col-6 col-md-4 col-lg-3">
                <label htmlFor={key} className="form-label small">
                  {key}
                </label>
                <input
                  id={key}
                  className="form-control form-control-sm"
                  type="text"
                  value={value}
                  onChange={(e) =>
                    setValues({ ...values, [key]: e.target.value })
                  }
                />
              </div>
            ))}
          </div>
          <div>
            <p className="small">
              API has two endpoints. The first runs a single simulation and the
              second runs 5 parallel simulations with incrementing start years.
              <br />
              URL excludes default parameter values. Can either be a GET request
              with query string parameters or a POST request with JSON in body.
            </p>
            {["single", "parallel"].map((type) => (
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="endpoint"
                  id={type}
                  checked={type === endpoint}
                  onChange={() => setEndpoint(type)}
                />
                <label className="form-check-label" htmlFor={type}>
                  {type}
                </label>
              </div>
            ))}
            <pre className="mt-3">
              <a href={url} target="_blank" rel="noreferrer">
                {url}
              </a>
            </pre>
            <button
              disabled={isLoading}
              className="btn btn-sm btn-outline-primary w-100"
              onClick={() => fetch(endpoint, parameters)}
            >
              Run
            </button>
          </div>
          <div className="mt-3">
            {!isLoading &&
              _.isUndefined(elapsedTime) &&
              "Click run to get output"}
            {isLoading && "Loading..."}
            {!isLoading &&
              !_.isUndefined(elapsedTime) &&
              `Elapsed time: ${elapsedTime}ms.`}
          </div>
        </div>
        <div className="flex-grow-1 flex-shrink-1 border overflow-auto my-3">
          {!_.isUndefined(data) && !isLoading && (
            <div>
              <ReactJson
                src={data}
                collapsed={2}
                displayDataTypes={false}
                quotesOnKeys={false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
